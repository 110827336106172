import asyncFactory from "@/client/extensions/modules/asyncComponentFactory.js";

export default [
  {
    path: "/:pathMatch(.*)*",

    component: asyncFactory("applications/justopp/views/general/404.vue"),
    props: {
      logoSrc: "images/logos/transparent.png",
    },
    meta: {
      layout: "default",
      layoutParams: {
        title: "justopp.404.pageTitle",
        subTitle: "justopp.404.pageSubtitle",
      },
      //    requiresGuest : true,
    },
  },
];
