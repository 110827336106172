//let conf = config;

//import asyncOps from "@/client/extensions/composition/asyncOperations.js";
//import { reactive, computed } from "vue";

export default {
  state: () => ({
    testA: "test A value",
    testB: "test B value",
    testC: {
      inner1: "inner 1 value",
      inner2: "inner 2 value",
      inner3: "inner 3 value",
      inner4: {
        innerD1: "inner d1 value",
        innerD2: "inner d2 value",
      },
    },
    testArray: ["item1", "item2", "item3", "item4"],
  }),
  mutations: {},
  actions: {},
  getters: {},
};
