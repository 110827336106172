export default ({ vendorId, userProfile, instance }) => {
  const hiddenOwnerIdField = {
    name: "OwnerId",
    type: "hidden",
    disabled: true,
    group: "hidden",
    value: userProfile.id,
  };

  const adminOwnerIdField = {
    name: "OwnerId",
    type: "Select2",
    translateList: false,
    valueKey: "id",
    labelKey: "fullName",
    label: "core.entity.ownerLabel",
    itemComponent: "iconItem",
    asyncListSource: "entity/User",
    asyncListLive: false,
    textFilterAsSaffronListFilter: true,
    asyncDataTextFilterParamName: "fullName",
    multiple: false,
    asyncListData: {
      simplified: true,
    },
    validation: {
      required: {},
    },
    group: "adminSettings",
  };

  const hiddenVendorIdField = {
    name: "VendorId",
    type: "hidden",
    disabled: true,
    group: "hidden",
  };

  const adminVendorIdField = {
    name: "VendorId",
    type: "Select2",
    translateList: false,
    valueKey: "id",
    labelKey: "name",
    label: "ecommerce.product.fields.vendor",
    itemComponent: "iconItem",
    asyncListSource: "entity/Vendor",
    asyncListLive: false,
    textFilterAsSaffronListFilter: true,
    asyncDataTextFilterParamName: "title",
    multiple: false,
    asyncListData: {
      simplified: true,
    },
    validation: {
      required: {},
    },
    group: "adminSettings",
  };

  const vendorIdField = userProfile.isAdmin ? adminVendorIdField : hiddenVendorIdField;
  const ownerIdField = userProfile.isAdmin ? adminOwnerIdField : hiddenOwnerIdField;

  const fields = {
    name: {
      name: "name",
      type: "text",
      label: "justopp.vendorBrands.fields.name",
      validation: {
        required: {},
        string: {},
      },
    },
    logoImage: {
      name: "logoImage",
      type: "quickMedia",
      valueField: "url",
      label: "justopp.vendorBrands.fields.logoImage",
      validation: {
        required: {},
      },
    },
    description: {
      name: "description",
      type: "editor",
      label: "justopp.vendorBrands.fields.description",
      validation: {
        required: {},
      },
    },
    VendorId: vendorIdField,
    OwnerId: ownerIdField,
  };

  return {
    fields: fields,
    groups: {
      hidden: {
        component: "block",
        props: {
          style: "display: none",
        },
      },
    },
  };
};
