import {
  omit,
  group,
} from "@/client/applications/justopp/assets/static/forms/util";

const formConfig = {
  groups: {
    companyDetails: {
      component: "FormFieldSet",
      props: {
        class: "FormFieldSet company-details",
        maxFieldsPerRow: 2,
      },
    },
  },
};

omit(formConfig, [
  "password",
  "UserGroups",
  "uuid",
  "agreesToTos",
  "fullName",
  "email",
  "phoneNumber",
  "password",
  "profileImageHandler",
  "profileImageHandle",
  "profileImageUrl",
  "locale",
]);

export default formConfig;
