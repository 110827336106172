import Home from "@/client/views/Home.vue";
import asyncFactory from "@/client/extensions/modules/asyncComponentFactory.js";

export default [
  {
    path: "/general/404",
    name: "404",
    component: asyncFactory("applications/justopp/views/general/404.vue"),
    props: {
      logoSrc: "images/logos/transparent.png",
    },
    meta: {
      layout: "default",
      layoutParams: {
        title: "justopp.404.pageTitle",
        subTitle: "justopp.404.pageSubtitle",
      },
      //    requiresGuest : true,
    },
  },
  {
    path: "/general/unauthorised",
    name: "unauthorised",
    component: asyncFactory("applications/justopp/views/general/Unauthorised.vue"),
    props: {
      logoSrc: "images/logos/transparent.png",
    },
    meta: {
      layout: "default",
      layoutParams: {
        title: "justopp.unauthorized.pageTitle",
        subTitle: "justopp.unauthorized.pageSubtitle",
      },
      //    requiresGuest : true,
    },
  },
];
