export const edit = (formConfig) => (fields, key, value) => {
  fields.forEach((field) => {
    // destructuring in case there are no fields
    const fields = { ...formConfig.fields };
    const fieldOptions = { ...fields[field] };
    fieldOptions[key] = value;
    fields[field] = fieldOptions;
    formConfig.fields = fields;
  });
};

export const omit = (formConfig, fields) => {
  edit(formConfig)(fields, "omit", true);
};

export const group = (formConfig, groupName, fields) => {
  edit(formConfig)(fields, "group", groupName);
};
