export default {
  methods: {
    scrollToElement(hash) {
      if (utilities.isSSR()) {
        return true;
      }

      let element = document.querySelector(hash);

      if (!element) return true;

      const offset = -120;
      const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
