export default {
  $validator: (value) => {
    // data stracture validation
    if (typeof value !== "object" || !value) {
      return false;
    }

    if (!value.hasOwnProperty("prefix") || !value.hasOwnProperty("number")) {
      return false;
    }

    // prefix validation - string, 4 digits max and starts with +
    if (
      typeof value.prefix !== "string" ||
      !value.prefix.startsWith("+") ||
      value.prefix.length > 4
    ) {
      return false;
    }

    // number validation
    if (typeof value.number !== "string" || value.number.length < 5) {
      return false;
    }

    // number must be numeric
    if (!/^\d+$/.test(value.number)) {
      return false;
    }

    // prefix needs to be number, apart from the leading +
    if (!/^\d+$/.test(value.prefix.slice(1))) {
      return false;
    }

    return true;
  },
};


