export default {
  user: {
    devRegistrationData: false,
  },
  auction: {
    minBidIncrease: 500,
    defaultDealDurationDays: 14,
  },
  deals: {
    shuffleDealsInList: true,
  },
};
