import {
  omit,
  group,
} from "@/client/applications/justopp/assets/static/forms/util";

const formConfig = {
  fields: {
    profileImageUrl: {
      type: "mediaUrl",
      class: "last",
    },
    profileImageHandle: {
      placeholder: "entity.user.fields.profileImageHandle",
    },
    profileImageHandler: {
      placeholder: "entity.user.fields.profileImageHandler",
    },
  },
  groups: {
    userDetails: {
      component: "FormFieldSet",
      props: {
        // title: "justopp.userRegistration.fields.personalEmail",
        class: "form__fieldset--plain user-details margin-2xl-top",
        maxFieldsPerRow: 3,
      },
    },
    contactDetails: {
      props: {
        class: "FormFieldSet",
        maxFieldsPerRow: 1,
      },
    },
    contactDetails2: {
      props: {
        class: "FormFieldSet",
        maxFieldsPerRow: 1,
      },
    },
  },
};

group(formConfig, "userDetails", [
  "fullName",
  "email",
  "phoneNumber",
  "password",
  "profileImageHandler",
  "profileImageHandle",
  "profileImageUrl",
]);

omit(formConfig, [
  "password",
  "UserGroups",
  "uuid",
  "agreesToTos",
  "interestedIn",
  "website",
  "companyId",
  "city",
  "address",
  "country",
  "companyName",
  "personalEmail",
  "personalPhoneNumber",
  "position",
  "fullName2",
  "personalEmail2",
  "personalPhoneNumber2",
  "position2",
  "locale",
  "zipCode",
]);

export default formConfig;
