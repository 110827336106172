// method for parallel async module loading
import parallelDynamicImport from "@/client/utilities/parallelDynamicImport.js";

let isSSR = utilities.isSSR();
let usingSSR = process.env.VUE_APP_USE_SSR && process.env.VUE_APP_USE_SSR !== "false";

let appName = process.env.VUE_APP_APPLICATION_NAME;

let { getApplicationParts, getApplicationPartsSSR } = require("@/client/applications/" +
  appName +
  "/" +
  appName +
  ".js");

let saffronAppGlobalKey = config.saffronAppGlobalKey;

let createApplication = async (context) => {
  // import all the stuff we need (application exports, and createApp, using a single await for maximum performance
  let importDefinition = [
    { importKey: "createApp", targetKey: "createApp", promise: import("vue") },
    {
      importKey: "createSSRApp",
      targetKey: "createSSRApp",
      promise: import("vue"),
    },
  ];

  let imports = await Promise.allSettled([
    getApplicationParts(),
    parallelDynamicImport(importDefinition),
  ]);
  let parts = imports[0].value;
  let createApp;

  if (usingSSR) {
    createApp = imports[1].value.createSSRApp; // for any SSR app - we need this. on server it renders, on client it hydrates
  } else {
    createApp = imports[1].value.createApp; // spa
  }

  // create the application
  let application = createApp(parts.app);

  // vue configuration
  // deprecated
  //application.config.unwrapInjectedRef = true;

  /*
  application.config.warnHandler = function (msg, context, trace) {
    try {
      console.log('Vue warning:', msg);
      console.log('Vue context:', context);
      console.log('Trace:', trace);
    } catch(e) {
      console.log('Vue warning:', 'unable to get message');
    }


  };
*/
  application.isSaffronHydrating = usingSSR && !isSSR;
  parts.store.isSaffronMounted = false;
  parts.store.isSaffronHydrating = application.isSaffronHydrating;

  application.provide(saffronAppGlobalKey, {});

  if (parts.store) {
    application.use(parts.store);
    application.store = parts.store;

    // fetch config if needed
    // do not check config via store, becuase the module may not exist in app. if the useServerConfig is on,
    // assume app developer took care of it and left us the correct store API
    if (config.useServerConfig && config.waitForServerConfig) {
      await parts.store.dispatch("config/updateFromServer", {
        allowLoadFromWindow: true,
      });
    }

    if (config.useServerConfig && !config.waitForServerConfig) {
      parts.store.dispatch("config/updateFromServer");
    }
    parts.store.isSSRHydrated = false;

    // store hydration
    if (usingSSR && !isSSR) {
      if (window && window.getInitialSaffronStoreState) {
        let ssrState = getInitialSaffronStoreState();
        parts.store.replaceState(ssrState);
        parts.store.isSSRHydrated = true;
      }
    }
  }

  // create router, if the key is a method
  if (parts.router && typeof parts.router === "function") {
    parts.router = parts.router(application);
  }

  if (parts.router) {
    application.use(parts.router);
  }

  if (parts.router && parts.store) {
    parts.router.$store = parts.store;
    parts.router.isStoreSet = true;
    parts.store.$router = parts.router;
    parts.store.isRouterSet = true;
  }

  // apply all the extensions it asked for
  for (const [index, extension] of Object.entries(parts.extensions || {})) {
    application.use(extension);
  }

  let result = {
    app: application,
    router: parts.router || false,
    store: parts.store || false,
  };

  application.isSaffronHydrating = false;
  parts.store.isSaffronHydrating = false;
  // SSR routing, injection
  if (context && context.url && parts.router) {
    // inject cookies to our app and store
    try {
      application.injectServerCookies(context.cookies);
      parts.store.cookie.injectServerCookies(context.cookies);
    } catch (e) {
      console.log("error injecting cookies", e);
    }

    // handle SSR routing
    return await new Promise((fulfil) => {
      parts.router.push(context.url);
      parts.router.isReady().then(() => {
        fulfil(result);
      });
    });
  } else {
    return result;
  }
};

let mountAppClientSide = async (app, router, store) => {

  try {
    app.mount("#app");
  } catch (e) {
    console.log("mount error");
    console.log(e);
  }

  // we are mounting remove global spinner
  try {
    window.saffronPreloadSpinnerRemove();
  } catch (e) {}

  if (!usingSSR) {
    return;
  }

  if (config.useServiceWorker) {
    require("./registerServiceWorker");
  } else {
    require("./unregisterServiceWorker");
  }

  // ssr hydration animation activation
  document.body.style.opacity = "1";
  //hydrated
  let activationEvents = ["mousemove", "touchstart", "click", "keyup"];
  let activationTimeout;
  let isActivating = false;
  // todo: we can watch the store isSaffronMounted to activate.
  // this is our plan to prevent the flicker on re-hydration
  let activate = () => {
    if (isActivating) {
      return;
    }

    isActivating = true;
    clearTimeout(activationTimeout);

    try {
      document.querySelector("#app").classList.add("hydrated");
    } catch (e) {
      console.log(e);
    }

    utilities.setClientTimeout(() => {
      try {
        document.querySelector("#app").classList.add("activated");
      } catch (e) {
        console.log(e);
      }
    }, 10);

    // make sure thing were really activated
    utilities.setClientTimeout(() => {
      try {
        document.querySelector("#app").classList.add("hydrated");
        document.querySelector("#app").classList.add("activated");
      } catch (e) {
        console.log(e);
      }
    }, 1500);
    activationEvents.forEach((type) => window.removeEventListener(type, activate));
  };

  // activate the application on user interaction or after a little while
  activationEvents.forEach((type) => window.addEventListener(type, activate));
  utilities.setClientTimeout(activate, 1000);
  app.isSaffronHydrating = false;
  app.store.isSaffronHydrating = app.isSaffronHydrating;
};

if (!isSSR) {
  // automatically mount app if this is on the client side
  createApplication().then(({ app, router, store }) =>
    mountAppClientSide(app, router, store)
  );
}

export { createApplication, getApplicationParts, getApplicationPartsSSR };
