module.exports = {
  name: "JustOpp",
  iconPaths: {
    favicon32: "justopp/images/favicons/favicon-32x32.png",
    favicon16: "justopp/images/favicons/favicon-16x16.png",
    appleTouchIcon: "justopp/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "justopp/images/favicons/safari-pinned-tab.svg",
    msTileImage: "justopp/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#FA9348",
};
