const _ = require('lodash')

const NavFactory = class NavFactory {
  items = {};

  constructor(items) {
    this.setItems(items);
    return this;
  }

  getItemIndex(key, target) {
    let index =0;
    if (! target) {
      target = this.items;
    }

    let keys = Object.keys(target);
    return keys.indexOf(key);
  }

  moveItemAfter(sourceKey,targetKey) {
    let itemCopy = this.getItemCopy(sourceKey);
    this.deleteItem(sourceKey);
    this.addItemAfter(targetKey,sourceKey,itemCopy);
    return this;
  }

  setItemOrder(targetKey, order, targetList) {
    if ( ! targetList) {
      targetList = this.items;
    }
    let currentIndex = 0;
    let found = false;
    let group1 = {};
    let group2 = {};
    let targetItem = null;
    for (const [key,value] of Object.entries(targetList)) {

      if (key === targetKey) {
        targetItem = value;
        found = true;
        continue;
      }

      if (currentIndex < order) {
        group1[key] = value;
      } else {
        group2[key] = value;
      }
      currentIndex = currentIndex +1;
    }

    if( ! found) {
      warn(`navFactory setItemOrder - item not found with key ${targetKey}`);
      return this;
    }
    let final = {
      ...group1,
      [targetKey]: targetItem,
      ...group2,
    }

    for (const key in targetList) {
      delete targetList[key];
    }
    for (const key in final) {
      targetList[key] = final[key];
    }
  //  _.assign(targetList, final);

    return this;
  }

  addItemAfter(targetKey, newKey, newItem, target) {
    if ( ! target) {
      target = this.items;
    }
    if (!(targetKey in target)) {
      warn(`nav helper - addItemAfter: target key "${targetKey}" does not exist.`);
      return this;
    }

    if (typeof newKey !== 'string' && typeof newKey !== 'number') {
      warn(`nav helper - addItemAfter: invalid new key, must be a proper property name.`);
      return this;
    }

    const updatedItems = {};
    for (const currentKey in target) {
      updatedItems[currentKey] = target[currentKey];
      if (currentKey === targetKey) {
        updatedItems[newKey] = newItem;
      }
    }

    // clear the old item, and assign the new
    for (const key in target) {
      delete target[key];
    }
    _.assign(target, updatedItems);
    return this;
  }

  addItemsAfter(targetKey, items, target) {
    let nextTargetKey = targetKey;
    for (const [key,item] of Object.entries(items)) {
      this.addItemAfter(nextTargetKey, key, item, target);
      nextTargetKey = key;
    }
    return this;
  }

  addItemBefore(targetKey, newKey, newItem, target) {
    if ( ! target) {
      target = this.items;
    }
    if (!(targetKey in target)) {
      warn(`nav helper - addItemBefore: target key "${targetKey}" does not exist.`);
      return this;
    }

    const updatedItems = {};

    for (const currentKey in target) {
      if (currentKey === targetKey) {
        updatedItems[newKey] = newItem;
      }
      updatedItems[currentKey] = target[currentKey];
    }

    // clear the old item, and assign the new
    for (const key in target) {
      delete target[key];
    }
    _.assign(target, updatedItems);

    return this;
  }

  addItemsBefore(targetKey, items, target) {
    let nextTargetKey = targetKey;
    for (const [key,item] of Object.entries(items)) {
      this.addItemBefore(nextTargetKey, key, item, target);
      nextTargetKey = key;
    }
    return this;
  }

  getItem(key) {
    return this.getItemByKey(key);
  }

  getItemCopy(key) {
    let item = this.getItem(key);
    if (! item) {
      return false;
    }

    return _.cloneDeep(item);

  }

  getItemChild(key,childKey) {
   try {
     return this.getItemByKey(key).children[childKey];
   } catch(e) {
     warn(`nav helper - getItemChild: target key "${key}" does not exist, or it has no children, or the child ${childKey} does not exist on that item.`);
     return false;
   }
  }

  getItemChildCopy(key,childKey) {
    try {
      let item =  this.getItemByKey(key).children[childKey];

      if ( ! item) {
        warn(`nav helper - getItemChild: target key "${key}" does not exist.`);
        return false;
      }

      return _.cloneDeep(item);
    } catch(e) {
      warn(`nav helper - getItemChild: target key "${key}"  has no children, or the child ${childKey} does not exist on that item.`);
      return false;
    }
  }


  getItemByKey(key) {
    return this.items[key];
  }

  addItemAfterChild(targetKey,childKey,newKey,newItem) {
    let target = this.getItem(targetKey);
    if (!target || ! target?.children) {
      warn(`nav helper - addItemAfterChild: target key "${targetKey}" does not exist.`);
      return this;
    }


    return this.addItemAfter(childKey,newKey,newItem,target.children);
  }

  addItemBeforeChild(targetKey,childKey,newKey,newItem) {
    let target = this.getItem(targetKey);
    if (!target || ! target?.children) {
      warn(`nav helper - addItemBeforeChild: target key "${targetKey}" does not exist.`);
      return this;
    }

    return this.addItemBefore(childKey,newKey,newItem,target.children);
  }

  removeItem(key) {
    delete this.items[key];
    return this;
  }

  removeItems(keys = []) {
    keys.forEach(key => {
      this.removeItem(key);
    });
    return this;
  }

  deleteItem(key) {
    return this.removeItem(key);
  }

  deleteItemChild(key,childKey) {
    let target = this.getItemByKey(key);
    if (! target || ! target.children) {
      return this;
    }
    try {
      delete target.children[childKey];
    } catch(e) {

    }

    return this;
  }

  removeItemChild(key,childKey) {
    return this.deleteItemChild(key,childKey);
  }

  getLastItemKey() {
    const keys = Object.keys(this.items);
    if (keys.length === 0) {
      return false;
    }
    return keys[keys.length - 1];
  }

  getLastKey() {
    return this.getLastItemKey();
  }

  getFirstItemKey() {
    const keys = Object.keys(this.items);
    if (keys.length === 0) {
      return false;
    }
    return keys[0];
  }

  getFirstKey() {
    return this.getFirstItemKey();
  }

  setItems(items) {
    this.items = items;
    return this;
  }

  getItems() {
    return this.items;
  }

  toPlainObject() {
    return this.items;
  }

  addItem(key, item) {
    return this.addItemAfter(this.getLastItemKey(), key,item);
  }

  appendItem(key,item) {
    return this.addItem(key,item);
  }

  add(key,item) {
    return this.addItem(key,item);
  }


  prependItem(key, item) {
    return this.addItemBefore(this.getFirstItemKey(),key,item);
  }

  prepend(key,item) {
    return this.prependItem(key,item);
  }

  updateItemChild (targetKey,childKey,arg1,arg2) {
    let target = this.items[targetKey];
    if ( ! target || ! target.children) {
      warn(`nav helper - updateItemChild: target key "${targetKey}" does not exist.`);
      return this;
    }

    if ( ! target.children) {
      warn(`nav helper - updateItemChild: target key "${targetKey}" has no children.`);
      return this;
    }
    if ( ! target.children[childKey]) {
      warn(`nav helper - updateItemChild: target key "${targetKey}" has no "${childKey}" child.`);
      return this;
    }


    return this.updateItem(childKey, arg1,arg2, target.children);
  }

  /**
   * Update an item
   * targetKey - the item to update
   * Other arguments:
   * arg1 as object - replaces the target item
   * art1 as string and arg2 as anything - set the target[arg1] to arg2
   *
   * itemlist - reference, used internally to update a list instead of the local list
   * specifically to manipulate child items
   **/
  updateItem(targetKey, arg1,arg2, itemList) {
    if ( ! itemList) {
      itemList = this.items;
    }

    let target = itemList[targetKey];

    if ( ! target) {
      warn(`nav helper - updateItem: target key "${targetKey}" does not exist.`);
      return this;
    }

    // arg1 as function
    if (typeof arg1 === "function") {
      arg1(target);
      return this;
    }

    // arg1 as item and no arg 2
    if (arg1 && typeof arg1 === 'string' && typeof arg2 !== 'undefined') {
      target[arg1] = arg2;
      return this;
    }

    // arg1 and 2 as key value pair
    if ( arg1 && typeof arg1 === 'object' && typeof arg2 === 'undefined') {
      for (const key in target) {
        delete target[key];
      }
      _.assign(target, arg1);
      return this;
    }

    warn(`nav helper - updateItem: invalid signature.`);
    return this;
  }
}


export default NavFactory;
export {NavFactory, NavFactory as navFactory};

