import {
  omit,
  group,
} from "@/client/applications/justopp/assets/static/forms/util";

const formConfig = {
  fields: {
    translations: {
      group: "TranslationsGroup",
    },
  },
  groups: {
    details: {
      component: "FormFieldSet",
      props: {
        title: "justopp.vendorProfile.displayDetailsFieldsetTitle",
        class: "FormFieldSet",
      },
    },
    images: {
      component: "FormFieldSet",
      props: {
        title: "justopp.vendorProfile.imagesFieldsetTitle",
        class: "FormFieldSet",
      },
    },
    TranslationsGroup: {
      component: "CollapsableFieldSet",
      props: {
        title: "justopp.vendorDeal.translationsFieldsetTitle",
        initiallyOpened: false,
        class: "translations",
      },
    },
  },
};

omit(formConfig, ["translations"]);

group(formConfig, "details", ["name", "about"]);
group(formConfig, "images", [
  "heroImageUrl",
  "logoImageUrl",
  "smallLogoImageUrl",
]);

export default formConfig;
